export const landContent = {
  climate: `The canyon's climate tells the story of why this place was chosen for experimental architecture. Each season tests materials and designs against nature's full spectrum. Summer afternoons bake the canyon walls past 90 degrees, while nights can drop 40 degrees lower.\n\nMorning fog yields to fierce inland winds by afternoon. At night, the air reverses its flow toward the coast, bringing a silence that makes the canyon feel worlds away from campus. On the right, you can track these shifting conditions through a typical late spring day.\n\nWinter rains transform everything. Brown slopes burst green overnight, creek beds fill, and by spring, plants tower overhead. Year after year, this cycle of extremes shapes not just the landscape, but every piece of architecture that dares to stand here.`,

  vegetation: `The vegetation tells its story in layers. Along Brizzolara Creek, willows and bay laurels create cool shadows where ferns and mosses thrive even through summer's heat. Their roots grip the creek banks, holding earth in place through winter storms.\n\nHigher up, the terrain turns rugged and the plants grow tough. Here, Toyon bushes spread their leathery leaves wide, collecting precious sunlight. Chaparral Yucca rises from rocky soil like natural sculpture, its pale spears catching morning light.\n\nThe grasslands between fill most of the canyon, golden and wind-bent through summer and fall. But after winter rains, they transform. Green shoots carpet the slopes, and waves of wildflowers follow. Watch for the yellow blooms of Sticky Monkeyflower dotting the hillsides—it's one of the first signs that spring has arrived.`,

  wildlife: `Long before students built their first structure here, this was grazing land. That history still lives in the canyon—cattle and horses continue to roam these hills, shaping the landscape in unexpected ways. The original Blade Structure even had to be rebuilt after years of serving as a cow's favorite scratching post.\n\nThe wildlife here creates its own daily patterns. Turkey flocks claim the grasslands in late afternoon. Red-tailed hawks circle overhead, while deer emerge from the oak shadows to graze, particularly bold during quiet summer months.\n\nAfter winter storms, the creek fills with the deep croaking of Pacific chorus frogs. By summer, western fence lizards claim every sun-warmed surface, doing pushups on concrete remnants. This place is a living ecosystem where every resident, from hummingbirds to cattle, shape the canyon's story.`,

  geology: `The canyon's geology tells a story of immense forces at work. The Santa Lucia Mountains rose from the grinding collision of tectonic plates, their uplift carving out the ridges and valleys of today. These ancient movements left their signature in every outcrop and cliff face.\n\nBeneath the ground lies the Franciscan Formation, a complex mix of rock types. Sandstone outcrops break to the surface, while serpentine ridges trace ancient fault lines.\n\nThe soils here shape everything that's built in the canyon. Los Osos clay expands with winter rains and shrinks in summer heat, while Diablo soils crack and shift unpredictably. These aren't challenges for builders, they're opportunities. Every structure in the canyon proves that understanding the land leads to better design.`,

  hydrology: `Water is one of the most visible forces here. During the dry season, Brizzolara Creek runs quiet, but winter storms transform it into a powerful sculptor of the landscape. The creek cuts new channels, moves earth, and feeds a network of smaller streams that streak the hillsides.\n\nDeep underground, natural springs weave through serpentine fractures, eventually feeding into San Luis Obispo's watershed. Long before the city existed, this network of springs and creeks helped sustain the entire valley. Today, you can still spot traces of more recent history—old pipes and water systems from when caretakers lived here.\n\nEach season brings its own hydrology. Summer reduces the creek to a whisper, while winter rains paint the canyon green. On the right, a hydrology map highlights the state of the creeks today.`,
};
